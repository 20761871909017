<template>
  <div class="micro-app-container">
    <div
      class="micro-app-system-container"
      v-for="(system, index) in systemInfoList"
      :key="'system' + index"
    >
      <el-image
        fit="cover"
        class="micro-app-system-image"
        :src="system.icon"
      ></el-image>
      <div class="micro-app-system-label">{{ system.label }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const systemInfoList = ref([
  {
    icon: "./images/systems/222.jpg",
    label: "用户权限管理",
    key: "permission",
  },
  {
    icon: "./images/systems/223.png",
    label: "地理信息系统",
    key: "gis",
  },
  {
    icon: "./images/systems/224.png",
    label: "AI标注平台",
    key: "ai",
  },
  {
    icon: "./images/systems/225.png",
    label: "模型管理平台",
    key: "model",
  },
  {
    icon: "./images/systems/226.png",
    label: "知识库管理",
    key: "kn",
  },
  {
    icon: "./images/systems/222.jpg",
    label: "OA系统",
    key: "oa",
  },
  {
    icon: "./images/systems/223.png",
    label: "ERP系统",
    key: "erp",
  },
  {
    icon: "./images/systems/224.png",
    label: "分析评估",
    key: "ans",
  },
  {
    icon: "./images/systems/225.png",
    label: "物联网平台",
    key: "ddd",
  },
  {
    icon: "./images/systems/226.png",
    label: "视频管理系统",
    key: "flv",
  },
]);
</script>

<style scoped>
.micro-app-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.micro-app-system-container {
  display: inline-block;
  margin: 10px;
}
.micro-app-system-image {
  height: 240px;
  width: 360px;
}
.micro-app-system-label {
  text-align: center;
}
</style>
