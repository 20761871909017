import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Blog from "@/components/Blog";
import MicroApp from "@/components/MicroApp";
import PersonalSystem from "@/components/PersonalSystem";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: { name: "blog" },
    children: [
      {
        path: "blog",
        name: "blog",
        component: Blog,
      },
      {
        path: "micro-system",
        name: "micro-system",
        component: MicroApp,
      },
      {
        path: "personal",
        name: "personal",
        component: PersonalSystem,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
