<template>
  <div
    @click="onMenuClick(menu)"
    :class="activeMenu.text === menu.text ? 'menu-item-active' : null"
    class="menu-item"
    v-for="(menu, index) in menus"
    :key="'menu' + index"
  >
    <span class="iconfont" :class="menu.icon"></span>
    <span>{{ menu.text }}</span>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const menus = ref([
  {
    icon: "icon-beikongshuiwupingtaimenhu-tubiao_banbenguanlixiton",
    text: "个人博客",
    route: "/blog",
  },
  {
    icon: "icon-xitong",
    text: "系统管理",
    route: "/micro-system",
  },
  {
    icon: "icon-ziliaoguanli",
    text: "个人中心",
    route: "/personal",
  },
]);

const activeMenu = ref(menus.value[0]);
const router = useRouter();
const onMenuClick = (menu) => {
  activeMenu.value = menu;
  router.push(menu.route);
};
</script>

<style scoped>
.menu-item {
  display: inline-block;
  padding: 0 20px;
  letter-spacing: 2px;
  cursor: pointer;
}
.menu-item-active {
  background-color: white;
  color: #0695f4;
}
</style>
