<template>
  <router-view />
</template>

<style>
body,
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
