<template>
  <div class="blog-container">
    <div class="blog-left-container">
      <div
        class="blog-left-menu-item"
        v-for="(category, index) in categoryList"
        :key="'category' + index"
      >
        {{ category.text }}
      </div>
    </div>
    <div class="blog-center-container">
      <MdPreview
        read-only
        preview
        @change="onMdChanged"
        class="blog-center-md-edit"
        v-model="text"
      />
    </div>
    <div class="blog-right-container"></div>
  </div>
</template>

<script setup>
import { MdPreview } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { ref } from "vue";

const categoryList = ref([
  {
    key: "gis",
    text: "地理信息专栏",
    icon: "",
  },
  {
    key: "springCloud",
    text: "微服务专栏",
    icon: "",
  },
  {
    key: "vue",
    text: "Vue专栏",
    icon: "",
  },
  {
    key: "javascript",
    text: "Javascript专栏",
    icon: "",
  },
]);
const text = ref(
  "# 1、微服务介绍\n" +
    "[百度文库-微服务](https://baike.baidu.com/item/%E5%BE%AE%E6%9C%8D%E5%8A%A1/18758759?fr=ge_ala)\n" +
    "## 1.1 概念\n" +
    "&emsp;&emsp;微服务（或称微服务架构）是一种云原生架构方法，在单个应用中包含众多松散耦合且可单独部署的小型组件或服务。 这些服务通常拥有自己的技术栈，包括数据库和数据管理模型；通过一个REST API、事件流和消息代理组合彼此通信；以及按照业务能力进行组织，具有通常称为有界上下文的服务分隔线。\n" +
    "&emsp;&emsp;​微服务特点在于代码更容易更新 - 可以直接添加新特性或功能，而不必更新整个应用，团队可以对不同的组件使用不同的技术栈和不同的编程语言。组件可以相互独立地扩展，从而减少与必须扩展整个应用相关的浪费和成本（因为单个功能可能面临过多的负载）。\n" +
    "## 1.2 特征\n" +
    "&emsp;&emsp;微服务架构最经常得出的两个比较是整体架构和面向服务的架构（SOA）。\n" +
    "&emsp;&emsp;​微服务和整体架构之间的区别在于，微服务由许多较小的，松散耦合的服务组成一个应用程序，与大型，紧密耦合的应用程序的整体方法相反。\n" +
    "&emsp;&emsp;微服务和SOA之间的差异可能不太清楚。虽然可以在微服务和SOA之间形成技术对比，尤其是围绕企业服务总线（ESB）的作用，但将差异视为范围之一更容易。SOA是企业范围内的一项工作，旨在标准化所有服务之间相互交流和集成的方式，而微服务体系结构则是特定于应用程序的。\n" +
    "&emsp;&emsp;微服务在管理人员和项目负责人中至少与在开发人员中一样受欢迎。这是微服务的较不寻常的特征之一，因为架构热情通常是为实际工程师保留的。这样做的原因是微服务更好地反映了许多业务主管想要组建和运行其团队以及开发流程的方式。换句话说，微服务是一种架构模型，可以更好地促进所需的运营模型。\n" +
    "# 2、Spring Cloud\n" +
    "&emsp;&emsp;Spring Cloud是一系列框架的有序集合。它利用Spring Boot的开发便利性巧妙地简化了分布式系统基础设施的开发，如服务发现注册、配置中心、消息总线、负载均衡、断路器、数据监控等，都可以用Spring Boot的开发风格做到一键启动和部署。Spring Cloud并没有重复制造轮子，它只是将各家公司开发的比较成熟、经得起实际考验的服务框架组合起来，通过Spring Boot风格进行再封装屏蔽掉了复杂的配置和实现原理，最终给开发者留出了一套简单易懂、易部署和易维护的分布式系统开发工具包。\n" +
    "&emsp;&emsp;Spring Cloud 是分布式微服务架构的一站式解决方案，它提供了一套简单易用的编程模型，使我们能在 Spring Boot 的基础上轻松地实现微服务系统的构建。 Spring Cloud 提供以微服务为核心的分布式系统构建标准。\n" +
    "&emsp;&emsp;Spring Cloud 本身并不是一个开箱即用的框架，它是一套微服务规范，共有两代实现。\n" +
    "- Spring Cloud Netflix 是 Spring Cloud 的第一代实现，主要由 Eureka、Ribbon、Feign、Hystrix 等组件组成。\n" +
    "- Spring Cloud Alibaba 是 Spring Cloud 的第二代实现，主要由 Nacos、Sentinel、Seata 等组件组成。\n" +
    "# 3、Spring cloud Alibaba\n" +
    "## 3.1 介绍\n" +
    "&emsp;&emsp;Spring Cloud Alibaba 是阿里巴巴结合自身丰富的微服务实践而推出的微服务开发的一站式解决方案，是 Spring Cloud 第二代实现的主要组成部分。吸收了 Spring Cloud Netflix 微服务框架的核心架构思想，并进行了高性能改进。自 Spring Cloud Netflix 进入停更维护后，Spring Cloud Alibaba 逐渐代替它成为主流的微服务框架。\n" +
    "&emsp;&emsp;同时 Spring Cloud Alibaba 也是国内首个进入 Spring 社区的开源项目。2018 年 7 月，Spring Cloud Alibaba 正式开源，并进入 Spring Cloud 孵化器中孵化；2019 年 7 月，Spring Cloud 官方宣布 Spring Cloud Alibaba 毕业，并将仓库迁移到 Alibaba Github OSS 下。\n" +
    "## 3.2 版本\n" +
    "&emsp;&emsp;1、适配 Spring Boot 3.2，Spring Cloud 2023.x 版本及以上的 Spring Cloud Alibaba 版本按从新到旧排列如下表（最新版本用*标记）：\n" +
    "![](./images/1.png)\n" +
    "\n" +
    "&emsp;&emsp;2、每个 Spring Cloud Alibaba 版本及其自身所适配的各组件对应版本如下表所示：\n" +
    "![](./images/2.png)"
);

const onMdChanged = () => {
  console.log(text.value);
};
</script>

<style scoped>
.blog-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.blog-left-container {
  width: 300px;
  flex: 1 0 auto;
}

.blog-left-menu-item {
  height: 32px;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 20px;
  line-height: 32px;
  cursor: pointer;
}

.blog-left-menu-item:hover {
  color: #009ff1;
}

.blog-center-container {
  width: 100%;
}

.blog-center-md-edit {
  height: 100%;
  width: 100%;
}

.blog-right-container {
  width: 300px;
  flex: 1 0 auto;
}
</style>
