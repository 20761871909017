<template>
  <a class="footer-item" href="https://tc922.com/" target="_blank">
    关于天辰星图
  </a>
  <a class="footer-item" href="https://beian.miit.gov.cn/" target="_blank">
    渝ICP备2023007907号
  </a>
</template>
<style scoped>
.footer-item {
  display: inline-block;
  margin: 0 10px;
}
</style>
