<template>
  <div class="personal-container">
    <div class="personal-left-container">
      <div
        class="personal-left-menu-item"
        v-for="(category, index) in categoryList"
        :key="'category' + index"
      >
        {{ category.text }}
      </div>
    </div>
    <div class="personal-center-container">
      <el-descriptions title="基本信息">
        <el-descriptions-item label="用户名">天辰星图</el-descriptions-item>
        <el-descriptions-item label="电话号码">XXXXXX</el-descriptions-item>
        <el-descriptions-item label="所在市">重庆市</el-descriptions-item>
        <el-descriptions-item label="从事行业">
          <el-tag size="small">gis开发工程师</el-tag>
          <el-tag size="small">后端开发工程师</el-tag>
          <el-tag size="small">前端开发工程师</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="具体地址">
          重庆市沙坪坝区XXXXX街道XXXX小区
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const categoryList = ref([
  {
    key: "baseInfo",
    text: "基本信息",
    icon: "",
  },
  {
    key: "password",
    text: "密码管理",
    icon: "",
  },
  {
    key: "count",
    text: "账号设置",
    icon: "",
  },
  {
    key: "yinsi",
    text: "隐私设置",
    icon: "",
  },
  {
    key: "history",
    text: "浏览记录",
    icon: "",
  },
]);
</script>

<style scoped>
.personal-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.personal-left-container {
  width: 300px;
  flex: 1 0 auto;
}

.personal-left-menu-item {
  height: 32px;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 20px;
  line-height: 32px;
  cursor: pointer;
}

.personal-left-menu-item:hover {
  color: #009ff1;
}

.personal-center-container {
  width: 100%;
}

.personal-right-container {
  width: 300px;
  flex: 1 0 auto;
}
</style>
